import '../../node_modules/bootstrap/dist/js/bootstrap.min';

const Main = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className={'pt-5 pb-5 p-1'}>
                        <h1>Muutto Ja Oy</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;